<template>
<div class="container1150">
<header><strong>仿石透水砖</strong></header>
<main>
<p>
                仿石材透水砖为仿石材系列，免烧结成型，表面致密，
                面层通过添加超亲水添加剂实现快速透水，具有透水、滤水等功能；
                主要应用于市政广场等高层次场所
            </p>

<h2>产品特性</h2>
<p>● · 透水性强</p>
<p>● · 表面致密、耐风化、不褪色</p>
<p>● · 防腐蚀性好，耐酸耐碱</p>
<p>
                ● · 外观大气，美观，拥有大理石般的质感，可满足高标准的景观要求
            </p>
<div class="img-list">
<div class="img1"></div>
<div class="img2"></div>
</div>
<p>● ·优化流场设计，减小水头损失，对排水影响降至最小</p>
<p>● ·便捷的安装与维护</p>
<p>
                ·
                旋流沉砂隔油井可安装于我们提供的PE成品也可安装在现有的混凝土（砌砖）井中
            </p>
<div class="img3"></div>
<div class="img-list2">
<div class="img1"></div>
<div class="img2"></div>
</div>
</main>
</div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        // .img1 {
        //     // width: 100%;
        //     width: 550px;
        //     height: 400px;
        //     margin: 10px auto;
        //     background-image: url("/img/product/2-1.jpg");
        //     background-repeat: no-repeat;
        //     background-size: 100% 100%;
        // }
        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/3-1.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/3-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img3 {
            width: 1100px;
            height: 330px;
            margin: 10px auto;
            background-image: url("/img/product/3-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img-list2 {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 400px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/3-4.png");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 500px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/3-5.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
</style>